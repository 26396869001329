import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  hero,
  heroText,
  logo,
  mobileHero,
  mobileHeroText,
  viewMenu,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          maxHeight="750px"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          column
        >
          <CFView mt="5%" column center w="100%" textCenter>
            <CFImage
              w="85%"
              src={logo}
              alt="Gami Sushi Japanese Retaurant hero text"
              zIndex={98}
            />
            <CFView style={{ fontSize: 38 }} raleway xBold mt="10px">
              NOW TAKING
            </CFView>

            <CFView style={{ fontSize: 38 }} raleway xBold>
              ONLINE ORDERS
            </CFView>
            <CFView pulsate mt="15px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          maxWidth="1440px"
          w="100%"
          image={`url(${hero}) left / cover no-repeat`}
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          column
          justifyCenter
          alignStart
        >
          <CFView column justifyCenter alignStart ml="55px">
            <CFImage
              h="58vh"
              maxHeight="410px"
              mt="-13%"
              src={heroText}
              alt="Gami Sushi Japanese Retaurant hero text"
              zIndex={98}
            />
            <CFView pulsate mt="40px" ml="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
